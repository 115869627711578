<template>
    <div class="appmain">
        <el-form ref="form" :class="$i18n.locale == 'en' ? '' : 'forms'" :rules="rules" :model="form" :label-width="$i18n.locale == 'en' ? '150px' : '100px'" v-if="info">
            <el-form-item :label="$t('user.account')" prop="username">
                <el-input v-model="form.username" :placeholder="this.$t('reg.iphoneeamil')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('user.verificationCode')" prop="code">
                <el-input v-model="form.code">
                    <template slot="append">
                        <span style="cursor:pointer" v-if="textopen" @click="getcode">{{text}}</span>
                        <span v-if="!textopen">{{daojishi}}s</span>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.password')" prop="password">
                <el-input type="password" v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.confirmPassword')" prop="cpassword">
                <el-input type="password" v-model="form.cpassword"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.name')" prop="name">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.companyName')">
                <el-input v-model="form.company"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.companyIndustry')">
                <el-input v-model="form.industry"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.state')">
                <el-input v-model="form.country"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.address')" prop="address">
                <el-input v-model="form.address"></el-input>
            </el-form-item>
            <el-form-item label=" ">
                <div class="regbtn" @click="reg">
                    {{$t('reg.register')}}
                </div>
            </el-form-item>
        </el-form>
        <div v-if="!info">
            <div class="tubiao">
                <i class="el-icon-success"></i>
            </div>
            <div class="title">
                {{$t('reg.registeredSuccessfully')}}
            </div>
            <div class="tishi">
                {{$t('reg.rememberAccount')}}
            </div>
            <div class="caozuobtn">
                <div class="mashang" @click="nav('login')">{{$t('user.loginImmediately')}}</div>
                <div class="fanhui" @click="nav('index')">{{$t('user.backHomepage')}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { Message } from "element-ui";
import { reg,getCode } from "@/api/api"; 
export default {
    data() {
        var checkEmail = (rule, value, callback) => {
            const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
            const iphoneReg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
    
            if (!value) {
                return callback(new Error(this.$t('login.notnull')))
            }
            setTimeout(() => {
                if (mailReg.test(value) || iphoneReg.test(value)) {
                    callback()
                } else {
                    callback(new Error(this.$t('reg.emtail')))
                }
            }, 100)
        }
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('reg.reenter')))
                // password 是表单上绑定的字段
            } else if (value !== this.form.password) {
                callback(new Error(this.$t('reg.inconformity')))
            } else {
                callback()
            }
        }
      return {
        textopen:true,
        text:this.$t('getCode'),
        daojishi:60,
        form: {},
        info:true,
        rules: {
            username:[
                {  required: true,validator: checkEmail, trigger: 'blur' }
            ],
            password: [
                { required: true, message: this.$t('reg.passwordmsg'), trigger: "blur" },
                { min: 5, max: 20, message: this.$t('reg.passwordmsg2'), trigger: 'blur' }
            ],
            code:[
                { required: true, message: this.$t('login.codemsg'), trigger: "blur" }
            ],
            cpassword:[
                { required: true, validator: validatePass2, trigger: 'blur' }
            ],
            phone: [
                { required: true, message: this.$t('reg.phonemsg'), trigger: "blur" },
                {
                    pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                    message: this.$t('login.phonemsg2'),
                    trigger: "blur"
                }
            ],
            address:[
                { required: true, message: this.$t('reg.addressmsg'), trigger: "blur" },
            ]
        },
        title:''
      }
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.keywords')
            },
            {
                name: 'description',
                content: this.$t('seo.description')
            }
        ]
        }
    },
    created(){
        this.title = this.$t('login.register')+'-'+this.$t('seo.title')
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
    methods:{
        reg(){
            this.$refs["form"].validate(valid => {
                if (valid) {
                    reg(this.form).then(res=>{
                        this.info = false
                    })
                }
            });
        },
        nav(url){
            this.$router.push('/'+url)
        },
        async getcode(){
            let value = this.form.username
            const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
            const iphoneReg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
            if (!value) {
                Message({
                    message:this.$t('login.usermsg'),
                    type:'error'
                })
            }
            if(mailReg.test(value) || iphoneReg.test(value)){
                this.textopen = false
                let data = {
                    username:this.form.username
                }
                this.timer = setInterval(()=>{
                    this.daojishi--
                    if(this.daojishi===0){
                        this.daojishi = 60
                        this.textopen = true
                        clearInterval(this.timer)
                    }
                },1000)
                const {data:res} = await getCode(data)
            }else{
                Message({
                    message:this.$t('reg.emtail'),
                    type:'error'
                })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 30%;
        margin-top: 5%;
    }
    @media screen and (max-width: 480px) {
        .appmain{
            width: 90%;
        }
    }
    .regbtn{
        width: 100%;
        height: 48px;
        background: #0052D9;
        font-size: 14px;
        color: #fff;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
    }
    .tubiao{
        font-size: 100px;
        text-align: center;
        color: #52C41A;
    }
    .title{
        text-align: center;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
    }
    .tishi{
        text-align: center;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 2%;
    }
    .caozuobtn{
        width: 200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        margin-top: 3%;
        .mashang{
            background: #0052D9;
            width: 88px;
            height: 32px;
            color: #fff;
            cursor: pointer;
        }
        .fanhui{
            background: #FFFFFF;
            width: 88px;
            height: 32px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            color: #000000;
            cursor: pointer;
        }
    }
</style>
<style  scoped>
    .forms>>>.el-form-item label:after {
        content: " ";
        display: inline-block;
        width: 100%;
    }
 
    .forms>>>.el-form-item__label {
        text-align: justify;
        height: 20px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #333333;
    }
    /* 这里去除必选字段的*,这个符号会造成一定影响,去掉之后我用了li列表进行定位,在前面加上" * ". */
    .forms>>>.el-form-item.is-required .el-form-item__label:before {
        content: none !important;
    }
</style>